<template>
  <div>
    <div class="money-audit">
        <div class="result-line">
            <span class="label">审核结论:</span>
            <div class="el-radio-outer">
                <el-radio
                        :label="1"
                        v-model="auditResult"
                >通过
                </el-radio>
                <el-radio
                        :label="2"
                        v-model="auditResult"
                >拒绝
                </el-radio>
            </div>
        </div>
        <div class="result-line bottom" v-show="auditResult == 2">
            <div class="reject-text">拒绝原因:</div>
            <div class="select-outer">
                <el-select
                        v-model="rejectReason"
                        placeholder="请选择"
                        popper-class="select-self"
                >
                    <el-option
                            class="select-el-option"
                            v-for="(item,index) in rejectReasonOption"
                            :label="item.value"
                            :key="index"
                            :value="item.value"
                    >
                    </el-option>
                    <el-option
                            class="select-el-option"
                            label="自定义"
                            value="-1"
                    >
                    </el-option>
                </el-select>
                <div v-if="rejectReason == -1"
                        class="textarea-outer">
                    <el-input
                            class="textarea-el"
                            type="textarea"
                            v-model="rejectReasonSelf"
                    ></el-input>
                </div>
            </div>
        </div>


        
    </div>
    <el-row class="check-line" v-if="tableStatus===5 || isWithdrawOperate"   v-show="auditResult == '1'">
          <span class="label">支付渠道:</span>
          <el-radio-group v-model="withdrawType" style="padding-right:20px;">
            <el-radio :label="1">手动出金
              <el-upload v-if="withdrawType==1"  v-show="auditResult == '1'"
            class="upload"
                     ref="uploadcomponent"
            action="''"
            :http-request="handleSuccess"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :on-preview="handlePreview"
            :limit=1
            list-type="picture"
          >
<!--            :on-success="handleSuccess"-->
            <el-button size="small" type="primary">点击上传转账凭证</el-button>
            <div slot="tip" class="el-upload__tip"><uploadImgTips></uploadImgTips></div>
          </el-upload>


            </el-radio>



            <el-radio :label="2"  v-show="data.payTypeId == 4 || data.type == 7">自动出金

              <el-select
              v-if="withdrawType!=1"
            v-show="auditResult == '1'"
            v-model="withdrawChannel"
            clearable
            placeholder="支付渠道"
            class="filter-item"
            style="width: 130px;"
          >
            <el-option
              v-for="item in withdrawChannelTypes"
              :key="item.channelCode"
              :label="item.channelName"
              :value="item.channelCode"
            />
          </el-select>


            </el-radio>
          </el-radio-group>

      </el-row>
      <el-row class="result-line"  v-if="tableStatus===5 || isWithdrawOperate">
       
        

         
          
      </el-row>
      </div>
</template>

<script>
  import { getDict } from '@/api/system/dict'
  import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile,gePayTypeCommon,parseTime } from '@/utils/index'
  import { uploadFile } from '@/api/data'
  import { watch } from 'less';
  export default {
    name: 'moneyAudit',
    props: {
     tableStatus: {
        type: Number,
        required: true
      },
      isWithdrawOperate:{
        type: Boolean,
        default: false,
      },
      withdrawChannelTypes:{
        type: Array,
        default: [],
      },
      
      data: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        auditResult: 1,
        rejectReason: '',
        rejectReasonSelf: '',
        rejectReasonOption: [],
        fileList:[],
        withdrawType:"",
        withdrawChannel:"",
        proofImg:""
      }
    },
    created(){
        this.getAuditReasonsDist();
    },
    methods: {
      sendReason() {
        let params = {
          decision: this.auditResult,
        }
        if(this.auditResult == 2){
          if(this.rejectReason != -1){
            params.rejectReason = this.rejectReason
          }else {
            params.rejectReason = this.rejectReasonSelf;
          }
        }
        this.$emit('send-reason',params);
      },
      getAuditReasonsDist() {
        getDict({
          type: 'depCommRejectReason'
        }).then((res) => {
          this.rejectReasonOption = res.content;
        })
      },
      beforeUpload(file) {
           return limitImageSizeFormat(file,this);
      },
      handleSuccess(item){
        getBase64(item.file).then(res => {
          let file = base64toFile(res);
          uploadFile(file).then((res) => {
            this.proofImg = res.data.content[0].url;
            this.$emit("handleSuccess",this.proofImg);
          });
        });
      },
      handlePreview(file) {
        console.log(file);
      },
    }
  }
</script>
<style lang="less" scoped>
    .label {
        font-size: 18px;
        padding: 10px 10px;
    }
    

    .check-line{
      display: flex;
      width: calc(100% - 100px);
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 60px;
      .upload{
        float: right;
        position: absolute;
        top: 0px;
        left: 130px;
      }
      .el-select{
        position: absolute;
        top: 0px;
        left: 130px;
      }
      .el-radio-group{
        padding-right: 20px;
        position: absolute;
        left: 100px;
        top: 6px;
      }
      .el-radio{
        width: 100%;
        text-align: left;
        line-height: 30px;
      }
    }
    .result-line {
      display: flex;
      width: calc(100% - 100px);
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

        &.bottom {
            align-items: flex-start;
            margin-top: 10px;

            .reject-text {
                margin-right: 10px;
            }

            .textarea-el {
                margin-top: 10px;
            }

            .select-el,
            .textarea-el {
                width: 300px;
            }
        }
    }
</style>
